<template>
  <div>
    <query-form
      ref="queryRef"
      :query-form.sync="queryRole"
      :form="form"
    >
      <template v-slot:buttons>
        <b-button
          variant="primary"
          class="mr-1"
          @click="search"
        >Search</b-button>
        <b-button
          variant="secondary"
          class="mr-1"
          @click="reset"
        >Reset</b-button>
        <b-button
          v-hasBtn="`merchant_role_management`"
          variant="success"
          @click="addRole"
        >+New</b-button>
      </template>
    </query-form>
    <b-card>
      <b-overlay
        :show="roleLoading"
      >
        <b-table
          ref="refRoleListTable"
          :items="roleList"
          responsive
          :fields="tableColumns"
          primary-key="roleId"
          show-empty
          empty-text="No matching records found"
          class="position-relative"
        >
          <!-- Column: role_id -->
          <template #cell(role_id)="data">
            <div style="width: 150px">
              {{ data.value }}
            </div>
          </template>
          <!-- Column: role_name -->
          <template #cell(role_name)="data">
            <div style="width: 150px">
              {{ data.value }}
            </div>
          </template>
          <!-- Column: description -->
          <template #cell(description)="data">
            <div style="word-break: break-all;">
              {{ data.value }}
            </div>
          </template>
          <!-- Column: created_at_ms -->
          <template #cell(created_at_ms)="data">
            <span class="text-nowrap"> {{ updateDate(data.value, timeZone) }}</span>
          </template>
          <!-- Column: operations -->
          <template #cell(operations)="data">
            <b-button
              v-hasBtn="`merchant_role_management`"
              variant="primary"
              class="btn-icon mr-1"
              size="sm"
              @click="editRole(data.item)"
            >
              <feather-icon
                icon="Edit3Icon"
              />
            </b-button>
            <b-button
              v-hasBtn="`merchant_role_management`"
              variant="secondary"
              class="btn-icon"
              size="sm"
              @click="deleteRole(data.item)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span
                class="text-muted mr-1"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalList"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePagination"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    <!-- New Role -->
    <b-modal
      ref="newRoleModel"
      :title="newRoleTitle"
      cancel-variant="outline-secondary"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      size="lg"
      hide-footer
    >
      <b-overlay
        :show="addRoleLoading"
      >
        <validation-observer
          ref="addRoleForm"
        >
          <b-form class="mt-1 add-role-form">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Role Name"
                  label-for="roleName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Role Name"
                    vid="roleName"
                    rules="required"
                  >
                    <b-form-input
                      id="roleName"
                      v-model="roleNameModel"
                      placeholder="Role Name"
                      autocomplete="off"
                      :formatter="value => sliceStr(value, 10)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Description"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="descriptionModel"
                    placeholder="Description"
                    autocomplete="off"
                    rows="4"
                    :formatter="value => sliceStr(value, 300)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-card
              v-if="isShowMenu"
              border-variant="none"
              body-border-variant="none"
              title="Features"
            >
              <div
                v-for="(menu, index) in menuItems"
                :key="menu.feature_id"
              >
                <b-row
                  style="background-color: #dfdcfd;"
                >
                  <b-col
                    md="4"
                    class="text-center menu-parent"
                  >
                    {{ menu.feature_name }}
                  </b-col>
                  <b-col
                    md="4"
                    class="menu-parent"
                  >
                    <b-form-checkbox
                      v-model="menu.isSelected"
                      :name="`some-radio${index}`"
                      :value="`${menu.feature_id}-edit`"
                      class="custom-control-primary"
                      @change="editAll(`${menu.feature_id}-edit`, index)"
                    >
                      edit all
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    md="4"
                    class="menu-parent"
                  >
                    <b-form-checkbox
                      v-model="menu.isSelected"
                      :name="`some-radio${index}`"
                      :value="`${menu.feature_id}-view`"
                      class="custom-control-primary"
                      @change="viewAll(`${menu.feature_id}-view`, index)"
                    >
                      view all
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row
                  v-for="(menuChild, indexChild) in menu.children"
                  :key="`${menuChild.feature_id}-${indexChild}`"
                  class="menu-child"
                >
                  <b-col
                    md="4"
                    class="text-center"
                  >
                    {{ menuChild.feature_name }}
                  </b-col>
                  <b-col
                    md="4"
                  >
                    <b-form-checkbox
                      v-model="menuChild.isSelected"
                      :name="`some-radio${menuChild.feature_name}`"
                      :value="`${menuChild.feature_id}-edit`"
                      class="custom-control-primary"
                      @change="childEditAll(`${menuChild.feature_id}-edit`, index)"
                    >
                      edit
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    md="4"
                  >
                    <b-form-checkbox
                      v-model="menuChild.isSelected"
                      :name="`some-radio${menuChild.feature_name}`"
                      :value="`${menuChild.feature_id}-view`"
                      class="custom-control-primary"
                      @change="childViewAll(`${menuChild.feature_id}-view`, index)"
                    >
                      view
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
            </b-card>
            <div class="text-center mt-1 mb-2">
              <b-button
                variant="primary"
                size="lg"
                @click="saveNewRole"
              >
                Save
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  defineComponent, ref, computed, getCurrentInstance, watch, onMounted,
} from '@vue/composition-api'
import {
  BCard, BForm, BRow, BCol, BInputGroup, BFormInput, BButton, BTable, BPagination, BFormTextarea, BFormCheckbox, BFormGroup, BFormRadio, BOverlay,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { updateDate, sliceStr } from '@/libs/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import roleFeature from './roleFeatures'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const { $store } = proxy
    const form = ref([
      {
        label: 'Role Name', itype: 'input', imodel: 'roleName', placeholder: 'Role Name',
      },
      // {
      //   label: 'Role ID', itype: 'input', imodel: 'roleId', placeholder: 'Role ID',
      // },
    ])
    const queryRole = ref({})
    const perPage = ref(10)
    const currentPage = ref(1)
    const totalList = ref(0)
    const refRoleListTable = ref(null)
    const roleList = ref()
    const tableColumns = [
      { key: 'role_id', label: 'Role ID' },
      { key: 'role_name', label: 'Role Name' },
      { key: 'created_at_ms', label: 'creation date' },
      { key: 'user_counts', label: '#users' },
      { key: 'description', label: 'description' },
      { key: 'operations', label: 'operations' },
    ]
    const dataMeta = computed(() => {
      const localItemsCount = refRoleListTable.value ? refRoleListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalList.value,
        totalList: totalList.value,
      }
    })
    const roleLoading = ref(false)
    const search = () => {
      const params = {
        role_name: queryRole.value.roleName, role_id: queryRole.value.roleId, page_size: perPage.value, page_no: currentPage.value,
      }
      try {
        setTimeout(async () => {
          roleLoading.value = true
          const res = await proxy.$api.fetchRoleList(params)
          roleLoading.value = false
          const { code, data } = res.data
          if (code === 200) {
            const { list, pagination } = data
            roleList.value = list
            totalList.value = pagination.total_count
          }
        }, 0)
      } catch (error) {
        console.log(error)
      }
    }
    const reset = () => {
      queryRole.value = {}
      proxy.$refs.queryRef.reset()
    }
    const changePagination = page => {
      proxy.currentPage = page
    }
    const deleteRoleFunc = ({ roleId, roleName, userCounts }) => {
      // 如果想删掉角色的话，#USERS 必须等于零。不然报错提醒会说
      if (userCounts > 0) {
        proxy.$swal({
          title: '',
          text: 'The role must have zero users connected before it can be deleted.',
          icon: 'warning',
          confirmButtonText: 'Okey',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      proxy.$swal({
        title: '',
        // eslint-disable-next-line camelcase
        text: `Are you sure you wish to delete the role: ${roleName}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const res = await proxy.$api.removeRole({ role_id: roleId })
          const { code, message } = res.data
          if (code === 200) {
            proxy.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Delete success',
                success: 'success',
              },
            },
            {
              position: 'top-center',
            })
            currentPage.value = 1
            search()
          } else {
            proxy.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: `${message}`,
                variant: 'danger',
              },
            }, {
              position: 'top-center',
            })
          }
        }
      })
    }
    const deleteRole = row => {
      deleteRoleFunc({ roleId: row.role_id, roleName: row.role_name, userCounts: row.user_counts })
    }
    const timeZone = computed(() => $store.state.merchant.timeZone, { immediate: true, deep: true })

    // model
    const {
      isShowMenu,
      menuItems,
      roleNameModel,
      descriptionModel,
      newRoleTitle,
      currentRoleId,
      getMenuItems,
      editMenuItems,
      newFeatureList,
      addRoleLoading,
      // function
      editAll,
      viewAll,
      childEditAll,
      childViewAll,
      featureListData,
      saveNewRoleFunc,
    } = roleFeature()
    const addRole = () => {
      getMenuItems()
      roleNameModel.value = ''
      descriptionModel.value = ''
      currentRoleId.value = null
      newRoleTitle.value = 'New Role'
      proxy.$refs.newRoleModel.show()
    }

    const saveNewRole = () => {
      newFeatureList.value = []
      const params = {
        role_meta: {
          role_name: roleNameModel.value,
          description: descriptionModel.value,
        },
        feature_list: [],
      }
      if (currentRoleId.value) {
        params.role_id = currentRoleId.value
      }
      params.feature_list = featureListData(menuItems.value)
      saveNewRoleFunc(params, () => {
        currentPage.value = 1
        search()
      })
    }
    const editRole = row => {
      currentRoleId.value = row.role_id
      newRoleTitle.value = 'Edit Role'
      proxy.$refs.newRoleModel.show()
      setTimeout(async () => {
        await editMenuItems(row)
      }, 0)
    }

    watch([currentPage, perPage], () => { search() })

    onMounted(() => { search() })

    return {
      form,
      queryRole,
      perPage,
      currentPage,
      totalList,
      refRoleListTable,
      roleList,
      tableColumns,
      dataMeta,
      timeZone,
      roleLoading,
      // func
      search,
      reset,
      addRole,
      editRole,
      saveNewRole,
      changePagination,
      deleteRole,
      // model
      roleNameModel,
      descriptionModel,
      newRoleTitle,
      menuItems,
      addRoleLoading,
      // 权限弹框操作
      isShowMenu,
      editAll,
      viewAll,
      childEditAll,
      childViewAll,

      updateDate,
      sliceStr,
    }
  },
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormTextarea,
    BFormCheckbox,
    BFormGroup,
    BFormRadio,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    required,
  },
})
</script>
<style lang="scss">
  .menu-parent {
    margin: 10px 0;
  }
  .menu-child {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .add-role-form {
    height: 465px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
</style>
